// @import url("https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:wght@300;400;600;700&display=swap");
// @import url("https://fonts.googleapis.com/css2?family=Heebo:wght@700&display=swap");
// @import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap');

// or

//@font-face {
//  font-family: "Font";
//  src: url("./../fonts/Font/Font-Medium.woff2") format("woff2"),
//  url("./../fonts/Font/Font-Medium.woff") format("woff");
//  font-weight: 500;
//  font-style: normal;
//  font-display: swap;
//}

@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:wght@300;400;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Heebo:wght@700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=PT+Sans+Narrow&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,100..900;1,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto+Flex:opsz,wght@8..144,100..1000&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,opsz,wght@0,6..12,200..1000;1,6..12,200..1000&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap');
@import url('https://fonts.googleapis.com/css?family=Roboto+Slab:100,200,300,regular,500,600,700,800,900');

@font-face {
	font-family: 'Bebas Neue';
	font-weight: 700;
	font-style: normal;
	src: url('./../fonts/BebasNeueBold.eot');
	src: url('./../fonts/BebasNeueBold.eot') format('embedded-opentype'),
		url('./../fonts/BebasNeueBold.woff2') format('woff2'),
		url('./../fonts/BebasNeueBold.woff') format('woff'),
		url('./../fonts/BebasNeueBold.ttf') format('truetype'),
		url('./../fonts/BebasNeueBold.svg#BebasNeueBold') format('svg');
	font-display: swap;
}
