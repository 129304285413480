body {
  margin: 0;
  padding: 0;
  min-width: 320px;
  font-family: "Roboto", Arial, sans-serif;
  font-size: 14px;
  line-height: 130%;
  font-weight: 400;
  color: #000000;
  background-color: #ffffff;
}

h1, h2, h3, p {
  line-height: 130%;
}

img {
  max-width: 100%;
  height: auto;
}

.container {
  position: relative;
  margin: 0 auto;
  padding: 0 35px;
  width: 100%;
  max-width: 1400px;
  @media (width <= 720px) {
    padding: 0 15px;
  }
}
.hide {
    display: none;
}
